import React from "react";

function AccountDetailsCard({ accountName, accountNumber, bank, amountToPay }) {
  return (
    <div className="border-[1px] border-shuttlelanePurple border-dashed p-3 mt-7 rounded-lg bg-blue-50 flex flex-col gap-y-2">
      <div className="flex flex-col">
        <p className="font-bold text-sm">Account Name:</p>
        <p className="text-sm -mt-1">{accountName}</p>
      </div>
      <div className="flex flex-col">
        <p className="font-bold text-sm">Account Number:</p>
        <p className="text-sm -mt-1">{accountNumber}</p>
      </div>
      <div className="flex flex-col">
        <p className="font-bold text-sm">Bank Name:</p>
        <p className="text-sm -mt-1">{bank}</p>
      </div>
      <div className="flex flex-col">
        <p className="font-bold text-sm">Amount to pay:</p>
        <p className="text-sm -mt-1">{amountToPay}</p>
      </div>
    </div>
  );
}

export default AccountDetailsCard;
