// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import VendorDashboardNavbar from "../../../../components/ui/Vendor/VendorDashboardNavbar";
import VendorTopBar from "../../../../components/ui/Vendor/VendorTopBar";
import { fetchVendorEarnings } from "../../../../redux/slices/vendorSlice";
import { Helmet } from "react-helmet";
import moment from "moment";
import { FaDownload, FaEllipsis, FaXmark } from "react-icons/fa6";
import { usePDF } from "react-to-pdf";
import Modal from "react-modal";
import shuttlelaneLogo from "../../../../assets/logos/logo.png";

function VendorDashboardEarningsPage() {
  const { token, isLoading, vendor, earnings } = useSelector(
    (store) => store.vendor
  );
  const dispatch = useDispatch();

  // Mobile navbar handler
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(fetchVendorEarnings({ vendorId: vendor?._id, token }));
    }
  }, [token]);

  // Current payout
  const [currentPayout, setCurrentPayout] = useState();
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [isPDFVisible, setIsPDFVisible] = useState(false);
  // Pdf ref
  // const pdfRef = useRef();
  const { toPDF, targetRef } = usePDF({
    filename: `Shuttlelane Invoice - ${vendor?.companyName} from ${moment(
      currentPayout?.startDate
    ).format("MMMM, Do YYYY")} - ${moment(currentPayout?.endDate).format(
      "MMMM, Do YYYY"
    )}.pdf`,
  });

  function handleDownloadPDF(payout) {
    setCurrentPayout(payout);
    console.log("CURRENT PAYOUT:", currentPayout);
    console.log("CURRENT PAYOUT:", payout);
    setIsPDFLoading(true);
    const timeout = setTimeout(() => {
      setIsPDFLoading(false);
      toPDF();

      return () => clearTimeout(timeout);
    }, 1000);
  }

  useEffect(() => {
    if (isPDFLoading) {
      setIsPDFVisible(true);
      const timeout = setTimeout(() => {
        setIsPDFVisible(false);
      }, 1400);
      return () => clearTimeout(timeout);
    }
  }, [isPDFLoading]);

  return (
    <div className="">
      <Helmet>
        <title>Vendor Earnings - Shuttlelane Portal Vendor Dashboard</title>
      </Helmet>
      <ToastContainer />
      {/* Navbar here */}
      <VendorDashboardNavbar
        link="earnings"
        isNavbarOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
      />

      {/* PDF Modal */}
      <Modal
        isOpen={isPDFVisible}
        onRequestClose={() => setIsPDFVisible(false)}
        className="flex flex-col h-full min-h-screen w-full lg:px-40 px-7 bg-white bg-opacity-50"
      >
        <div className="flex items-center justify-end">
          <FaXmark
            size={20}
            onClick={() => setIsPDFVisible(false)}
            className="cursor-pointer"
          />
        </div>

        <div
          ref={targetRef}
          className={`w-full p-12 ${isPDFVisible ? "block" : "hidden"}`}
        >
          {/* Header */}
          <div>
            <div className="flex flex-row w-full items-end justify-between">
              <div className="flex flex-col w-full">
                <div className="flex flex-row items-center justify-between">
                  <div className="">
                    <div className="max-w-[130px] min-w-[130px] w-[130px]">
                      <img
                        src={shuttlelaneLogo}
                        alt="Thank you for choosing Shuttlelane!"
                        className="w-full object-contain"
                      />
                    </div>

                    <div className="flex flex-col">
                      <small className="mt-[10px]">
                        Email: booking@shuttlelane.com
                      </small>
                      <small>Tel: +234 903 000 9108</small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-end">
                <h3>Invoice</h3>

                <div className="flex flex-col items-end">
                  <span className="text-sm text-right w-max">
                    Invoice to: {vendor?.companyName}
                  </span>
                  <span className="text-sm text-right w-max">
                    Email Address: {vendor?.companyEmail}
                  </span>
                </div>
              </div>
            </div>
            <div className="h-[1px] my-3 w-full bg-gray-100" />
          </div>

          <div className="w-full mt-20">
            {/* Table header */}
            <div className="flex lg:w-auto w-max justify-between items-baseline mb-4 pb-4 border-b-[.3px] border-b-gray-100 mt-2">
              <p className="w-[33.3%] text-md text-shuttlelaneBlack">
                Invoiced week
              </p>
              <p className="w-[33.3%] text-md text-center text-shuttlelaneBlack">
                Jobs completed
              </p>
              <p className="w-[33.3%] text-md text-right text-shuttlelaneBlack">
                Total
              </p>
            </div>

            {/* Table body */}
            <div className="flex lg:w-auto w-max justify-between items-baseline mb-4 pb-4 border-b-[.3px] border-b-gray-100 mt-2">
              <p className="w-[33.3%] text-md flex flex-col text-shuttlelaneBlack">
                <p className="text-sm -mt-[1px]">
                  <strong>From:</strong>{" "}
                  {moment(currentPayout?.startDate).format("MMMM, Do YYYY")}
                </p>
                <p className="text-sm -mt-[1px]">
                  <strong>To:</strong>{" "}
                  {moment(currentPayout?.endDate).format("MMMM, Do YYYY")}
                </p>
              </p>

              <p className="w-[33.3%] text-md text-center text-shuttlelaneBlack">
                <p className="text-sm">{currentPayout?.jobs}</p>
              </p>

              <p className="w-[33.3%] text-md text-right text-shuttlelaneBlack">
                {currentPayout?.bookingDetails?.bookingCurrency?.symbol ?? "₦"}
                {Intl.NumberFormat("en-US", {}).format(
                  currentPayout?.totalBookingRate
                )}
              </p>
            </div>

            <div className="flex lg:w-auto w-max justify-between items-baseline mb-4 pb-4 mt-2">
              <p className="w-[33.3%] text-md text-shuttlelaneBlack"></p>
              <p className="w-[33.3%] text-md text-center text-shuttlelaneBlack">
                Sub Total
              </p>

              <p className="w-[33.3%] text-right text-md text-shuttlelaneBlack font-semibold">
                {currentPayout?.bookingDetails?.bookingCurrency?.symbol ?? "₦"}
                {Intl.NumberFormat("en-US", {}).format(
                  currentPayout?.totalBookingRate
                )}
              </p>
            </div>

            <div className="flex lg:w-auto w-max justify-between items-baseline mb-4 pb-4 mt-2">
              <p className="w-[33.3%] text-md text-shuttlelaneBlack"></p>
              <p className="w-[33.3%] text-md text-center text-shuttlelaneBlack">
                Tax (0%)
              </p>

              <p className="w-[33.3%] text-right text-md text-shuttlelaneBlack font-semibold">
                {currentPayout?.bookingDetails?.bookingCurrency?.symbol ?? "₦"}
                0.00
              </p>
            </div>

            <div className="flex lg:w-auto w-max justify-between items-baseline mb-4 pb-4 border-b-[.3px] border-b-gray-100 mt-2">
              <p className="w-[33.3%] text-md text-shuttlelaneBlack"></p>
              <p className="w-[33.3%] text-md text-center text-shuttlelaneBlack font-semibold">
                Total
              </p>

              <p className="w-[33.3%] text-right text-md text-shuttlelaneBlack font-semibold">
                {currentPayout?.bookingDetails?.bookingCurrency?.symbol ?? "₦"}
                {Intl.NumberFormat("en-US", {}).format(
                  currentPayout?.totalBookingRate
                )}
              </p>
            </div>
          </div>

          <div className="mt-32">
            <p>Thank you for choosing Shuttlelane!</p>
          </div>
        </div>
      </Modal>

      {/* Main content goes here */}
      <div className="w-full min-h-screen lg:pl-[6%] bg-white text-shuttlelaneBlack">
        <div className="px-7 py-5 relative">
          {/* Top bar */}
          <VendorTopBar
            isNavbarOpen={isNavbarOpen}
            setIsNavbarOpen={setIsNavbarOpen}
          />

          {/* Main content */}
          <div className="mt-24 pt-2">
            <div className="w-full">
              <div className="w-full">
                <div>
                  <div className="">
                    <h2 className="font-bold text-2xl text-shuttlelaneBlack">
                      Invoices
                    </h2>
                    <p className="text-sm">
                      Access all your earnings on Shuttlelane
                    </p>
                  </div>

                  <div className="flex flex-col gap-y-4 mt-7">
                    {/* Invoice / payout card */}
                    {earnings?.map((earning) => (
                      <div className="cursor-pointer lg:max-w-[70%] lg:w-[70%] p-2 bg-blue-50 rounded-lg border-[1px] lg:border-[2px] border-dashed border-slate-300 w-full flex-row items-center justify-between">
                        <div className="flex flex-row w-full items-center justify-between">
                          <div className="flex flex-col gap-y-1">
                            <div className="flex flex-col">
                              <p className="text-sm -mt-[1px]">
                                <strong>From:</strong>{" "}
                                {moment(earning?.startDate).format(
                                  "MMMM, Do YYYY"
                                )}
                              </p>
                              <p className="text-sm -mt-[1px]">
                                <strong>To:</strong>{" "}
                                {moment(earning?.endDate).format(
                                  "MMMM, Do YYYY"
                                )}
                              </p>
                            </div>

                            <div className="flex items-center gap-x-1">
                              <div
                                className={`h-3 w-3 ${
                                  earning?.hasBeenSettled
                                    ? "bg-green-500"
                                    : "bg-yellow-500"
                                } rounded-full`}
                              ></div>
                              <span
                                className={`text-sm ${
                                  earning?.hasBeenSettled
                                    ? "text-green-500"
                                    : "text-yellow-500"
                                }`}
                              >
                                {earning?.hasBeenSettled
                                  ? "Settled"
                                  : "Pending Settlement"}
                              </span>
                            </div>
                          </div>

                          <div className="flex flex-col gap-y-5 h-full justify-between items-end">
                            <button
                              onClick={() => handleDownloadPDF(earning)}
                              className="flex flex-row items-center gap-x-1"
                            >
                              <FaDownload size={14} className="text-gray-400" />
                              <p className="text-xs font-medium text-gray-400">
                                Download PDF
                              </p>
                            </button>

                            <p className="text-xl font-semibold">
                              {earning?.bookingDetails?.bookingCurrency
                                ?.symbol ?? "₦"}
                              {Intl.NumberFormat("en-US", {}).format(
                                earning?.totalBookingRate
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                    {earnings?.length < 1 ? (
                      <div className="mt-7 border-[1px] border-gray-300 bg-slate-100 rounded-lg p-3 flex items-center justify-center text-center w-full">
                        <p className="text-sm">
                          Complete a job to see your earnings
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorDashboardEarningsPage;
