import React, { useState } from "react";
import { assignNewDriverToBooking } from "../../../../redux/slices/vendorSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { ImSpinner2 } from "react-icons/im";

function VendorReassignDriver({ toggleModal, driverOptions, bookingId }) {
  const { vendor, isLoading, token } = useSelector((store) => store.vendor);
  const dispatch = useDispatch();

  // Update / assign new driver to booking
  const [newlyAssignedDriver, setNewlyAssignedDriver] = useState();

  // FUNCTION: Handles assigning a car and driver to a booking
  async function handleAssignNewDriverToBooking() {
    if (!newlyAssignedDriver) {
      toast.error("You must assign a driver in order to save changes");
      return;
    }

    dispatch(
      assignNewDriverToBooking({
        token,
        vendorId: vendor?._id,
        bookingId: bookingId,
        driverId: newlyAssignedDriver?.value,
      })
    );
    toggleModal(false);
  }

  return (
    <div className="w-full flex flex-col mt-5">
      {/* Assign New Driver */}
      <div className="flex flex-col gap-y-1 w-full">
        <label htmlFor="driver" className="text-sm">
          Re-assign a new driver to this booking
        </label>
        <Select
          value={newlyAssignedDriver}
          onChange={(value) => {
            setNewlyAssignedDriver(value);
          }}
          options={driverOptions}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "transparent" : "transparent",
              borderWidth: state.isFocused ? "0" : "0",
              backgroundColor: "transparent",
              position: "relative",
              zIndex: 80,
              width: "100%",
              height: "100%",
            }),

            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              // fontSize: ".75rem",
            }),

            menuList: (baseStyles, state) => ({
              ...baseStyles,
              // fontSize: ".75rem",
            }),

            input: (baseStyles, state) => ({
              ...baseStyles,
              // fontSize: ".75rem",
            }),
          }}
          placeholder="Update driver"
          className="w-full h-12 flex items-center border-[0.3px] focus:outline-none border-gray-400 rounded-lg"
        />
      </div>

      <div className="mt-4 w-full flex flex-row items-center justify-start">
        <button
          onClick={() => handleAssignNewDriverToBooking()}
          className="rounded-lg h-14 w-full bg-shuttlelanePurple text-white flex items-center justify-center gap-x-3"
        >
          {isLoading ? (
            <ImSpinner2 size={16} className="animate-spin" />
          ) : (
            <p>Re-assign driver</p>
          )}
        </button>
      </div>
    </div>
  );
}

export default VendorReassignDriver;
