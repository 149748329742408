// @ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";

// Images
import arrowAsset from "../../../assets/images/arrow-asset.svg";
import shuttlelaneLogo from "../../../assets/logos/logo.png";
import DriverLoginForm from "../../../forms/driver/LoginForm";

function DriverLoginPage(props) {
  return (
    <div className="flex min-h-screen">
      <ToastContainer />
      <Helmet>
        <title>Driver Login: Access Your Shuttlelane Account</title>
      </Helmet>

      <div className="lg:w-[50vw] overflow-hidden h-screen driverSignupBg fixed z-[40] lg:flex flex-col justify-center items-center hidden">
        <div className="absolute overflow-hidden top-0 left-0 w-full h-full">
          <img
            src={arrowAsset}
            className="object-cover w-full h-full opacity-30"
            alt=""
          />
        </div>

        <div className="flex flex-col gapy-y-10">
          <h1 className="text-[17rem] spaceGroteskText text-white opacity-5">
            Driver
          </h1>
        </div>
      </div>
      {/* Driver Login Form */}
      <div className="lg:pl-[50%] w-full max-w-screen h-[89vh] overflow-x-hidden pb-16">
        <div className="w-full pt-10 px-7 flex relative">
          <img
            src={shuttlelaneLogo}
            alt="Shuttlelane"
            className="object-contain w-36"
          />
        </div>

        <DriverLoginForm />
      </div>
    </div>
  );
}

export default DriverLoginPage;
