// @ts-nocheck
import React, { useEffect, useState } from "react";
import AdminDashboardNavbar from "../../../../../components/ui/Admin/AdminDashboardNavbar";
import AdminTopBar from "../../../../../components/ui/Admin/AdminTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPayouts,
  settlePayout,
} from "../../../../../redux/slices/adminSlice";
import { ImSpinner2 } from "react-icons/im";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { FaRegLightbulb, FaXmark } from "react-icons/fa6";
import Modal from "react-modal";
import AccountDetailsCard from "../../../../../components/ui/AccountDetailsCard";
import moment from "moment";

function AdminDashboardPayoutsPage() {
  const { isLoading, token, payouts } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  // Mobile navbar handler
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  // Payout modal
  const [isPayoutDetailsModalOpen, setIsPayoutDetailsModalOpen] =
    useState(false);
  const [currentPayout, setCurrentPayout] = useState();

  // Chart Setup
  //   const [userDataByMonth, setUserDataByMonth] = useState();
  //   const state = {
  //     options: {
  //       chart: {
  //         id: "apexchart-example",
  //         fontFamily: "Poppins",
  //         style: {
  //           fontSize: ".8rem",
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         colors: ["#262471", "#262471"],
  //       },
  //       fill: {
  //         colors: ["#262471", "#262471"],
  //         pattern: {
  //           style: "circles",
  //           strokeWidth: 1,
  //           height: 1,
  //           width: 1,
  //         },
  //       },
  //       xaxis: {
  //         categories: [
  //           "Jan",
  //           "Feb",
  //           "Mar",
  //           "Apr",
  //           "May",
  //           "Jun",
  //           "Jul",
  //           "Aug",
  //           "Sep",
  //           "Oct",
  //           "Nov",
  //           "Dec",
  //         ],
  //       },
  //       yaxis: {
  //         labels: {
  //           show: true,
  //           minWidth: 0,
  //           maxWidth: 160,
  //           style: {
  //             colors: [],
  //             fontSize: "30px",
  //             fontFamily: "Poppins",
  //             cssClass: "text-xs",
  //           },
  //           formatter: (value) => {
  //             const formattedValue = Intl.NumberFormat("en-US", {}).format(value);
  //             return formattedValue;
  //           },
  //         },
  //       },
  //     },
  //     series: [
  //       {
  //         name: "",
  //         data: userDataByMonth,
  //       },
  //     ],
  //   };

  // Fetch Admin Accounts
  useEffect(() => {
    dispatch(fetchPayouts(token));
  }, [token]);

  async function handleMarkPayoutAsSettled() {
    console.log("CURRENT PAYOUT;", currentPayout);
    dispatch(
      settlePayout({
        token,
        userId: currentPayout?.userDetails?._id,
        week: currentPayout?.week,
        year: currentPayout?.year,
        name:
          `${currentPayout?.userDetails?.companyName}` ??
          `${currentPayout?.userDetails?.firstName} ${currentPayout?.userDetails?.lastName}`,
        total: `${
          currentPayout?.bookingDetails?.bookingCurrency?.symbol ?? "₦"
        }${Intl.NumberFormat("en-US", {}).format(
          currentPayout?.totalBookingRate
        )}`,
        email: `${currentPayout?.userDetails?.companyName} ? ${currentPayout?.userDetails?.contactEmail} : ${currentPayout?.userDetails?.email}`,
      })
    );

    setIsPayoutDetailsModalOpen(false);
  }

  return (
    <div className="">
      <Helmet>
        <title>Payouts | Shuttlelane Portal Admin Dashboard</title>
      </Helmet>

      <ToastContainer />

      {/* Payout Details Modal */}
      <Modal
        isOpen={isPayoutDetailsModalOpen}
        onRequestClose={() => setIsPayoutDetailsModalOpen(false)}
        className="flex h-full min-h-screen justify-center items-center lg:px-40 px-7"
      >
        <div className="bg-white pb-10 shadow-lg rounded-lg text-shuttlelaneBlack w-full min-h-[80%] max-h-[80%] h-[80%] lg:w-[60%] p-7 px-10 overflow-y-scroll shuttlelaneScrollbar">
          <div className="flex items-center justify-end">
            <FaXmark
              size={20}
              onClick={() => setIsPayoutDetailsModalOpen(false)}
              className="cursor-pointer"
            />
          </div>

          <div className="h-full flex flex-col gap-y-5 pb-20">
            <>
              {currentPayout && (
                <div className="flex items-center justify-center w-full pb-20">
                  <div className="w-full mt-10 p-7">
                    <h2 className="text-2xl font-semibold">Payout Details</h2>
                    <span className="text-sm text-slate-400">
                      Find payout details below, mark as settled after payment
                      has been processed
                    </span>

                    {/* Account Details card */}
                    <AccountDetailsCard
                      accountName={currentPayout?.userDetails?.accountName}
                      accountNumber={currentPayout?.userDetails?.accountNumber}
                      bank={currentPayout?.userDetails?.bank}
                      amountToPay={`${
                        currentPayout?.bookingDetails?.bookingCurrency
                          ?.symbol ?? "₦"
                      }${Intl.NumberFormat("en-US", {}).format(
                        currentPayout?.totalBookingRate
                      )}`}
                    />

                    {!currentPayout?.hasBeenSettled ? (
                      <button
                        className="h-10 w-full mt-5 px-4 rounded-md bg-shuttlelanePurple text-white disabled:bg-gray-300 disabled:text-slate-500"
                        onClick={() => {
                          handleMarkPayoutAsSettled();
                        }}
                        disabled={isLoading}
                      >
                        {!isLoading ? (
                          "I have sent the money"
                        ) : (
                          <ImSpinner2
                            size={16}
                            className={`${
                              isLoading
                                ? "text-shuttlelaneBlack"
                                : "text-shuttlelanePurple"
                            } animate-spin`}
                          />
                        )}
                      </button>
                    ) : (
                      <div className="flex items-center mt-5 gap-x-2 bg-green-100 rounded-lg w-full p-3">
                        <FaRegLightbulb size={18} className="text-green-500" />
                        <p className="text-green-500">
                          This payout has been settled
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </Modal>

      {/* Navbar here */}
      <AdminDashboardNavbar
        link="booking"
        sublink="payouts"
        isNavbarOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
      />

      {/* Main content goes here */}
      <div className="w-full min-h-screen lg:pl-[6%] bg-[#fff] text-shuttlelaneBlack">
        <div className="px-7 py-5 relative z-0">
          {/* Top bar */}
          <AdminTopBar
            isNavbarOpen={isNavbarOpen}
            setIsNavbarOpen={setIsNavbarOpen}
          />

          {/* Main content */}
          <div className="mt-24 pt-2">
            <div className="flex gap-x-5">
              <div className="w-full">
                <div className="flex flex-col">
                  <h1 className="font-semibold text-2xl">Payouts</h1>
                  <p className="text-sm">
                    Access payouts for drivers and vendors
                  </p>
                </div>
                {/* Payouts due this week */}
                <div className="w-full mt-7">
                  <div className="w-full rounded-lg border-[1.3px] lg:border-[.3px] p-3 border-gray-100 h-auto">
                    <div className="flex items-baseline justify-between">
                      <div className="flex items-center gap-x-2">
                        <p className="font-medium">Payouts</p>
                        <div className="h-2 w-2 rounded-full bg-green-500"></div>
                      </div>
                    </div>

                    <div className="w-full shuttlelaneScrollbarHoriz overflow-x-scroll">
                      {/* Table header */}
                      <div className="flex lg:w-auto w-max justify-between items-baseline mb-2 border-b-[.3px] border-b-gray-100 text-gray-400 mt-2">
                        <p className="w-[200px] lg:w-[20%] text-xs">Week</p>
                        <p className="w-[200px] lg:w-[20%] text-xs">
                          Full Name
                        </p>
                        <p className="w-[200px] lg:w-[20%] text-xs">
                          Amount Due
                        </p>
                        <p className="w-[200px] lg:w-[20%] text-xs">
                          Partner Type
                        </p>
                        <p className="w-[200px] lg:w-[20%] text-xs">
                          Payment Status
                        </p>
                      </div>

                      {/* Table body - Payment card */}
                      {payouts?.map((payout) => (
                        <div
                          onClick={() => {
                            setCurrentPayout(payout);
                            setIsPayoutDetailsModalOpen(true);
                          }}
                          className="cursor-pointer flex lg:w-auto w-max justify-between items-baseline mb-2 pb-2 border-b-[.3px] border-b-gray-100 text-shuttlelaneBlack mt-4"
                        >
                          <p
                            className={`w-[200px] lg:w-[20%] text-xs ${
                              isLoading && "text-gray-400"
                            }`}
                          >
                            {moment(payout?.startDate).format("MMM, Do")}{" "}
                            {" <-> "}
                            {moment(payout?.endDate).format("MMM, Do YYYY")}
                          </p>
                          <p
                            className={`w-[200px] lg:w-[20%] text-xs ${
                              isLoading && "text-gray-400"
                            }`}
                          >
                            {payout?.userDetails?.companyName // This shows the user is a vendor
                              ? payout?.userDetails?.companyName
                              : `${payout?.userDetails?.firstName}
                                    ${payout?.userDetails?.lastName}`}
                          </p>
                          <p
                            className={`w-[200px] lg:w-[20%] text-xs ${
                              isLoading && "text-gray-400"
                            }`}
                          >
                            {payout?.bookingDetails?.bookingCurrency?.symbol ??
                              "₦"}
                            {Intl.NumberFormat("en-US", {}).format(
                              payout?.totalBookingRate
                            )}
                          </p>
                          <p
                            className={`w-[200px] lg:w-[20%] text-xs ${
                              isLoading && "text-gray-400"
                            }`}
                          >
                            {payout?.userDetails?.companyName // This shows the user is a vendor
                              ? "Vendor"
                              : "Driver"}
                          </p>
                          <div className="min-w-[200px] w-[200px] lg:w-[20%] flex items-center gap-x-1">
                            <div
                              className={`h-2 w-2 ${
                                payout?.hasBeenSettled
                                  ? "bg-green-500"
                                  : "bg-yellow-500"
                              } rounded-full`}
                            ></div>
                            <span
                              className={`text-xs ${
                                payout?.hasBeenSettled
                                  ? "text-green-500"
                                  : "text-yellow-500"
                              }`}
                            >
                              {payout?.hasBeenSettled
                                ? "Settled"
                                : "Pending Settlement"}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    {payouts?.length < 1 && (
                      <div className="flex justify-center">
                        <p className="text-center text-sm">
                          No data to show for now...
                        </p>
                      </div>
                    )}

                    {isLoading && (
                      <div className="bg-white pb-10 shadow-lg rounded-lg text-shuttlelaneBlack w-full min-h-[80%] max-h-[80%] h-[80%] lg:w-[60%] p-7 px-10 overflow-y-scroll shuttlelaneScrollbar">
                        <div className="flex w-full h-full items-center justify-center">
                          <ImSpinner2
                            size={20}
                            className="cursor-loading animate-spin"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboardPayoutsPage;
