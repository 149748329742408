import React from "react";

function SimpleProfileCard({ user }) {
  return (
    <div className="flex items-center gap-x-2">
      <div className="h-24 w-24 rounded-full overflow-hidden bg-shuttlelanePurple text-white flex items-center justify-center">
        {user?.image ? (
          <img
            src={user?.image}
            alt={`${user?.firstName && user?.firstName} ${
              user?.lastName && user?.lastName
            } ${user?.companyName && user?.companyName}`}
            className="h-full w-full object-cover"
          />
        ) : (
          <p className="text-4xl font-bold uppercase">
            {user?.companyEmail?.split("")[0]}
          </p>
        )}
      </div>

      <div className="flex flex-col">
        <h2 className="font-semibold text-xl text-shuttlelaneBlack">
          {user?.firstName && user?.firstName}{" "}
          {user?.middleName && `${user?.middleName?.split("")[0]}.`}{" "}
          {user?.lastName && user?.lastName}{" "}
          {user?.companyName && user?.companyName}{" "}
        </h2>
        <small className="text-sm text-gray-400">
          Email Address:{" "}
          {user?.email ?? user?.companyEmail ?? user?.emailAddress}
        </small>
        <small className="text-sm text-gray-400 mt-1">
          Mobile: {user?.mobile ?? user?.phoneNumber}
        </small>
      </div>
    </div>
  );
}

export default SimpleProfileCard;
